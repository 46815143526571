<template>
    <div class="content">
        <div class="tool">
            <el-button type="primary" class="el-icon-plus" style="margin-right:20px" @click="AddClick"> 新增</el-button>
            <el-input placeholder="请输入名称" v-model="searchForm.searchInfo" style="width:200px">
                <el-button slot="append" icon="el-icon-search" @click="Search"></el-button>
            </el-input>
        </div>

        <el-table :data='DataList' border stripe :highlight-current-row='true'>
            <el-table-column align="center" prop="up_name" label="名称"></el-table-column>
            <el-table-column align="center" prop="up_addr" label="地址"></el-table-column>
            <el-table-column align="center" prop="up_contacts" label="联系人"></el-table-column>
            <el-table-column align="center" prop="up_phone" label="联系方式"></el-table-column>
            <el-table-column v-if="User.user_type===0" align="center" prop="sp_name" label="服务商"></el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <div class="link_btn">
                        <el-link type="primary" @click="Edit(scope.row)">编辑</el-link>
                        <el-link type="warning" @click="EditUser(scope.row)">操作员</el-link>
                        <el-link type="danger" @click="Del(scope.row)">删除</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination layout="prev, pager, next" @current-change="current_change" :total="total" background :page-size="searchForm.pageSize"></el-pagination>


        <!--运营商-->
        <el-dialog :title="title" :visible.sync="dialogVisible" width='30%' @open='Opened' :append-to-body='true'>
          <el-form :model="upForm" :rules="rules" ref="upForm" label-width="100px" size='medium'>
            <el-form-item label="名称" prop="up_name">
                <el-input v-model="upForm.up_name"></el-input>
            </el-form-item>
            <el-form-item v-if="User.user_type===0" label="上级服务商">
              <el-select v-model="upForm.up_sp_id" style="width:100%;">
                  <el-option label="无" :value="0"></el-option>
                  <el-option v-for="item in serDataList" :key="item.sp_id" :label="item.sp_name" :value="item.sp_id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="地址">
                <el-input v-model="upForm.up_addr"></el-input>
            </el-form-item>
            <el-form-item label="联系人">
              <el-input v-model="upForm.up_contacts"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" >
              <el-input v-model="upForm.up_phone"></el-input>
          </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>

        <!--操作员-->
        <el-dialog v-if="serUserDialogVisible" :title="title" :visible.sync="serUserDialogVisible" width='80%' :append-to-body='true'>
            <SerUser :beglongId='SerId' :userType="2"/>
            <span slot="footer" class="dialog-footer">
                <el-button @click="serUserDialogVisible = false">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import util from '../../util/util'
import SerUser from '../../components/servicer/SerUser.vue'

export default {
  data: function () {
    return {
      searchForm:{
        searchInfo:'',
        pageNo:1,
        pageSize:20,
      },
      total:0,

      User:null,

      dialogVisible: false,
      title: '',
      serUserDialogVisible: false,
      serDataList: [],
      DataList: [],
      upForm: {
        up_id: 0,
        up_sp_id:0,
        up_name: '',
        up_addr: '',
        up_phone: '',
        up_contacts: '',
      },
      rules: {
        up_name: [{ required: true, message: '请输入运营商名称', trigger: 'blur' }]
      },
      SerId: 0
    }
  },
  created () {
    this.GetDataList()
    this.User=util.JesToken()
  },
  components: {
    SerUser
  },
  methods: {
    GetDataList () {
      util.Get('UnionProd/getunionproddatalist',this.searchForm).then(res => {
        if (res.rpStatus === 10000) {
          this.DataList = res.list
          this.total=res.total
        }
      })
    },
    current_change(page){
      this.searchForm.pageNo=page
      this.GetDataList()
    },
    Search () {
      this.GetDataList()
    },
    Opened () {
      if (this.serDataList.length === 0&&this.User.user_type===0) {
        util.Get('serprod/getserproddatalist').then(res => {
          if (res.rpStatus === 10000) {
            this.serDataList = res.list
          }
        })
      }
    },
    AddClick () {
      this.dialogVisible = true
      this.title = '新增'
      this.upForm.up_id = 0
      this.upForm.up_name = ''
      this.upForm.up_addr = ''
      this.upForm.up_phone = ''
      this.upForm.up_sp_id = 0
      this.upForm.up_contacts = ''
      console.log(this.upForm)
    },
    Edit (row) {
      this.dialogVisible = true
      this.title = '修改'
      this.upForm = JSON.parse(JSON.stringify(row))
    },
    EditUser (row) {
      this.serUserDialogVisible = true
      this.title = '操作员'
      this.SerId = row.up_id
    },
    Del (row) {
      this.$confirm('确定删除运营商“' + row.up_name + '”？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        util.Get('UnionProd/del?id=' + row.up_id).then(res => {
          if (res.rpStatus === 10000) { this.GetDataList() } else { this.$message.error(util.ErrorMsg(res.RpStatus)) }
        })
      }).catch(e => e)
    },
    submit () {
      const flag = this.$refs.upForm.validate()
      if (flag) {
        util.Post('unionprod/edit', this.upForm).then(res => {
          if (res.rpStatus === 10000) {
            this.GetDataList()
            this.dialogVisible = false
          } else { this.$message.error(util.ErrorMsg(res.RpStatus)) }
        })
      }
    }
  }
}
</script>

<style scoped>
.content{
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
}
.tool{
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
}
.el-table{
width: 100%;
font-size:13px;
margin-top:10px;
}
.el-link{
    margin-right: 10px;
    font-size: 10px;
}
::v-deep .el-dialog{
  --el-bg-color:#000741 !important;
}
::v-deep .el-dialog__header{
  --el-text-color-primary:#1effff;
  --el-text-color-regular:#fff;
  padding: 0px !important;
  background-color: #309CE2;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-dialog__title{
    color: white;
}

::v-deep .el-dialog__headerbtn .el-dialog__close{
    color: white;
} 
</style>
